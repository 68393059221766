<template>
  <component :is="commonComponent" id="privacy" title="Privacy policy">
    <section id="privacy-content" class="pc_padding">
      <div class="common-content-row">
        TimeinCities values your privacy. This Privacy Policy (&quot;Policy&quot;) describes how we
        collect, use, and protect
        your personal information. By accessing or using the Website, you agree to the practices
        described in this Policy.
      </div>

      <div class="common-content-head">
        <span>1</span> Information Collection
      </div>

      <div class="common-content-row">
        We collect the following types of information through Firebase:
      </div>

      <div class="common-content-row">
        <ul>
          <li>
            <strong>Device Information</strong>: Including device type, operating system, and
            browser type.
          </li>
          <li>
            <strong>Usage Data</strong>: Including access times, page views, and click behavior.
          </li>
          <li><strong>Location Information</strong>: Based on IP address.</li>
        </ul>
      </div>

      <div class="common-content-head">
        <span>2</span> Information Use
      </div>

      <div class="common-content-row">
        We use the collected information for the following purposes:
      </div>

      <div class="common-content-row">
        <ul>
          <li>
            <strong>Optimize Website Performance</strong>: Analyze user behavior to improve website
            functionality and user
            experience.
          </li>
          <li>
            <strong>Personalize Services</strong>: Provide personalized content and recommendations
            based on user preferences.
          </li>
        </ul>
      </div>

      <div class="common-content-head">
        <span>3</span> Information Sharing
      </div>

      <div class="common-content-row">
        We do not sell, rent, or otherwise share your personal information with third parties,
        except in the following
        circumstances:
      </div>

      <div class="common-content-row">
        <ul>
          <li><strong>Legal Requirements</strong>: When required by law or government request.</li>
          <li>
            <strong>Business Transfers</strong>: In the event of a merger, acquisition, or sale of
            assets.
          </li>
        </ul>
      </div>

      <div class="common-content-head">
        <span>4</span> Information Security
      </div>

      <div class="common-content-row">
        We implement reasonable technical and organizational measures to protect your personal
        information from unauthorized
        access, use, or disclosure. However, no data transmission or storage system can be
        guaranteed to be 100% secure, and
        we cannot ensure absolute security.
      </div>

      <div class="common-content-head">
        <span>5</span> Modification of Policy
      </div>

      <div class="common-content-row">
        We reserve the right to modify this Policy at any time. The modified Policy will be posted
        on the Website and will
        become effective immediately upon posting. Your continued use of the Website constitutes
        acceptance of the modified
        Policy.
      </div>

      <div class="common-content-head">
        <span>6</span> Contact Us
      </div>

      <div class="common-content-row">
        If you have any questions or comments about this Policy, please contact us at:
      </div>

      <div class="common-content-row">
        <ul>
          <li>
            <strong>Email</strong>: <a
              href="mailto:timeincities.support@gmail.com">timeincities.support@gmail.com</a>
          </li>
        </ul>
      </div>
    </section>
  </component>
</template>

<script>
import common from '@/mixin/common.js';
import { mapGetters } from 'vuex';
// 按需动态引入 PC 和 Mobile 版本的 CommonPage
const PcCommon = () => import('@/pages/components/pc_common.vue');
const MobileCommon = () => import('@/pages/components/m_common.vue');

import '@/css/common.scss';

export default {
  mixins: [common],
  data () {
    return {
      commonComponent: null,
    };
  },
  metaInfo () {
    return {
      title: 'Privacy & Policy',
    };
  },
  computed: {
    ...mapGetters([
      'showDebug',
      'deviceType',
      'host',
    ])
  },
  created () {
    // 根据 deviceType 动态加载对应的组件
    this.commonComponent = this.deviceType === 'desktop' || this.deviceType === 'pc' ? PcCommon : MobileCommon;
  },
  async activated () {
    console.log(`${this.$options.name} Component activated-----------`);
  },
  async mounted () {
    console.log(`-------------------${this.$options.name} mounted`)
  },
  deactivated () {
    console.log(`${this.$options.name} Component deactivated-----------`);
  },
  beforeDestroy () {
    console.log(`-------------------${this.$options.name} beforeDestroy`);
  },
};
</script>